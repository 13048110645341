const { GetYoDigits } = require("Foundation");

// run foundation
jQuery(document).foundation();

// koma modular
//var sklikId = "100033708";     
// koma Brno
//var sklikId = "100039884";
// koma rent
//var sklikId = "100035525";
// koma austria
//var sklikId = null;
// koma family
//var sklikId = "100041849";

// map dollar back to jQuery
var $ = jQuery;

function isGAApi(apiHandle) {
  if (apiHandle == 'gtag') {
      return true
    } else {
      return true
    }
  return true  
}

function getSelectionText() {
  var text = "";
  if (window.getSelection) {
    text = window.getSelection().toString();
  } else if (document.selection && document.selection.type != "Control") {
    text = document.selection.createRange().text;
  }
  return text;
}

function extractEmails(text) {
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
}

function extractPhone(text) {
  var clean = text.replace(/ /g, '');
  return clean.match(/([0-9|\+|\(|\)]{9,15})/gi);
}

function hackSeznamSklik(sklikId) {
  if (sklikId !== null) {
    //console.log('renew/send: sklik iframe');
    $("#komasklik").remove();
    $('body').append('<iframe id="komasklik" width="119" height="22" frameborder="0" scrolling="no" src="//c.imedia.cz/checkConversion?c=' + sklikId + '&amp;color=ffffff&amp;v=0"></iframe>');
  }
}

function sendGaAdwords(action, email) {
  try {
    window.google_trackConversion({
      google_conversion_id: googleAdWordsID,
      google_custom_params: {
        action: action,
        email: email
      },
      google_remarketing_only: true
    });
  }
  catch(err) {
    console.warn("GAdwords error: " + err);
  }
}

function registerGGlinkClickEvents() {
  console.log("registerGGlinkClickEvents:On");
  jQuery('a').on('click', function (e) {
    //e.preventDefault(); // for debug only

    var el = $(e.currentTarget);
    var link = el.attr('href');
    if (!link || link === '' || link === '#') {
      return;
    }

    // click mail link
    if (link.indexOf("mailto:") >= 0) {
      e.preventDefault();
      var mailto = link.substr(7, link.length);
   
      gtag('event', 'click_email', {
        // Event parameters
        'clicked_email': mailto
      });
      console.log("gtag mail clicked: "+ mailto);
      //hackSeznamSklik("100060840");

      //sendGaAdwords('mail-click', mailto);
      //hackSeznamSklik();
      //fbq('track', '<COPY_OR_CLICK_ON_EMAIL>');
      setTimeout(function () {
        window.location.href = "mailto:" + mailto;
      }, 1000);

    }

    // click phone link
    if (link.indexOf("tel:") >= 0) {
        e.preventDefault();
        var phone = extractPhone(link)[0];
        console.log("phone clicked: " + phone);
        
        gtag('event', 'click_phone', {
          // Event parameters
          'clicke_phone': phone
        });
        console.log("gtag click phone: " + phone);
        //hackSeznamSklik("100060838");
        
        //sendGaAdwords('mail-click', mailto);
        //fbq('track', '<COPY_OR_CLICK_ON_EMAIL>');
        setTimeout(function () {
          window.location.href = "tel:" + phone;
        }, 1000);
  
      }

    // click pdf link
    if (link.indexOf(".pdf") >= 0) {
      e.preventDefault();    
      console.log("pdf download clicked");
      if ( isGAApi(gaApiVersion) ) {
        gtag('event', 'PDF_download', {
          // Event parameters
          'file_name': 'download' //TODO: render file name
        });
        console.log("gtag: event sent");
      } else { 
      }
      //sendGaAdwords('mail-click', mailto);
      //hackSeznamSklik();
      //fbq('track', '<COPY_OR_CLICK_ON_EMAIL>');
      setTimeout(function () {
        window.location.href = link;
      }, 1000);
    }

  });
}

function registerGACopyEvents() {
  console.log("registerGACopyEvents:On");

  $(document).bind('copy', function (event) {
      var text = getSelectionText();
    
      if (text.length < 40) {
        var mails = extractEmails(text);
        var phones = extractPhone(text);

        if (mails && mails.length > 0) {
          // send only one mail
          var mail = mails[0];
          console.log("copy mail " + mail);
      
        
          gtag('event', 'copy_email', {
            // Event parameters
            'email': mail
          });
          //hackSeznamSklik("100060840");
          
          //sendGaAdwords('mail-copy', mail);
          //Měřicí kód Sklik.cz
          //hackSeznamSklik();
          //console.log('send fb: COPY_OR_CLICK_ON_EMAIL');
          //fbq('track', '<COPY_OR_CLICK_ON_EMAIL>');
        }

        if (phones && phones.length > 0) {
            // send only one phone
            var phone = phones[0];
            console.dir("copy phone: " + phone);
          
            gtag('event', 'copy_phone', {
              // Event parameters
              'phone_number': phone
            });
            console.log("gtag copy phone: "+ phone);
            //hackSeznamSklik("100060838");
        }
      }
    }
  )
}

//handling product group b component opening
$( '.product-groupb-handler' ).on('click', function(event) {
  let blockId = $(this).data('block-id');
  let hideBtn = $(this).data('button-hide') ?? false;
  let e = $( "#" + blockId );
  if ( hideBtn ) {
    $(this).toggleClass('is-closed');
  };
  e.toggleClass('is-closed');
  if ( e.hasClass('is-closed') ) {
    this.innerHTML = $(this).data('open-label');
  } else {
    this.innerHTML = $(this).data('close-label');
  }
});

// sticky top bar
$('.main-kf-bar').on('sticky.zf.stuckto:top', function(){
  $(this).addClass('underpants');
  var tinyBar = document. getElementById("tiny-navbar");
  if ( tinyBar ) {
    $('.hp-heromodule').addClass('mt-offset-with-tb');
    $('.heromodule-content').addClass('mt-offset-with-tb');
  } else {
    $('.heromodule-content').addClass('mt-offset');
  }
}).on('sticky.zf.unstuckfrom:top', function(){
  $(this).removeClass('underpants');
  $('.heromodule-content').removeClass('mt-offset');
  $('.heromodule-content').removeClass('mt-offset-with-tb');
  $('.hp-heromodule').removeClass('mt-offset-with-tb');
})

//additional analytics for specific elements

//event click on button to KOMA-VIZOVICE
$( '.ga-linkto-km' ).on('click', function(event) {
  if (ccAnalytics) {
    console.log("Clicked external link to KOMA MODULAR (Vizovice)")
    gtag('event', 'click_link_VIZOVICE', {
      // Event parameters
      'event_category': 'button',
    });
  };
});

//event click on button to FASHION LINE
$( '.ga-linkto-fl' ).on('click', function(event) {
  if (ccAnalytics) {
    console.log("Clicked external link to FASHION LINE (external)")
    gtag('event', 'click_link_FASHION_LINE', {
      // Event parameters
      'event_category': 'button',
    });
  };
});

//video reveal lazy load if pageRevealVideos is defined
if (typeof pageRevealVideos !== 'undefined') {
  pageRevealVideos.forEach(element => {
    $(document).on('open.zf.reveal', '#modal-video-'+element.videoId+'[data-reveal]', function() {
      var iframeId = "#modal-video-" + element.videoId;
      console.log("oepning " + iframeId);
      $( iframeId ).append(element.content);
    });
    $(document).on('closed.zf.reveal', '#modal-video-'+element.videoId+'[data-reveal]', function() {
      var iframeId = "#video-iframe-" + element.videoId;
      $(iframeId).remove();
    });  
  });
};

// main
jQuery(function () {
  if (ccAnalytics) {
    registerGGlinkClickEvents();
    registerGACopyEvents();
  };
});